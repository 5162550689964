import { _Client } from '@/api-client';
import { PagedResultOfExaminerAvailabilityResponse, ExaminerAvailabilityResponse, ExaminerAvailabilityRequest, WorkStatusEnum, SaveAvailabilityRequest } from '@/api-client/client';
import { message } from "ant-design-vue";
import { defineComponent, getCurrentInstance, onMounted, reactive, ref, toRefs, unref } from "vue";
import Pagination from '@/components/Pagination/index.vue'
import LogDetail from '@/components/LogDetail/index.vue'
import moment from "moment-timezone"
import { useRouter } from 'vue-router';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: {
    Pagination,
    LogDetail,
    LeftOutlined,
    PlusOutlined
  },
  setup() {
    const { currentRoute, push, back } = useRouter();
    const route = unref(currentRoute);
    const date: Date = new Date(route.params.date.toString());
    const examinerList = ref<any>([]);
    const momentTimeZone = moment
    const internalInstance = getCurrentInstance();
    const workStatusEnum = WorkStatusEnum;
    const statusList = [
      { id: true, name: 'Enable' },
      { id: false, name: 'Disable' }
    ]
    const columns = [
      {
        title: 'Examiner No.',
        dataIndex: 'examinerNo',
        key: 'examinerNo',
        width: 120
      },
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        key: 'examinerName',
        width: 150
      },
      {
        title: 'Availability Status',
        dataIndex: 'availabilityStatus',
        key: 'availabilityStatus',
        width: 110
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        key: 'remark',
        width: 100
      }
    ]

    const showEditModal = ref<boolean>(false)
    const showAddCoreDayModal = ref<boolean>(false)
    const tableLoading = ref<boolean>(false)
    const saveLoading = ref<boolean>(false)
    const modifyExaminerId = ref<string>()
    const modifyExaminerName = ref<string>()
    const listState = reactive<{
      searchParames: ExaminerAvailabilityRequest,
      tableSource: PagedResultOfExaminerAvailabilityResponse
    }>({
      searchParames: new ExaminerAvailabilityRequest({
        pageIndex: 1,
        pageSize: 10,
        date: date
      }),
      tableSource: new PagedResultOfExaminerAvailabilityResponse(),
    })
    const onSelectChange = (keys: any) => {
    };
    async function getList() {
      listState.searchParames.date = date;
      try {
        tableLoading.value = true
        listState.tableSource = await _Client.availabilityManagementClient.examinerAvailabilityList(listState.searchParames)
        listState.tableSource.list = listState.tableSource.list!.map(item => ({
          ...item,
          initialClass: item.availabilityStatus, // 根据需要设置逻辑
        }));
      }
      finally {
        tableLoading.value = false
      }
    }
    const changeSearchParames = async () => {
      listState.searchParames.pageIndex = 1;
      await getList()
    };
    async function resetSearchParams() {
      listState.searchParames = new ExaminerAvailabilityRequest({
        pageIndex: 1,
        pageSize: 10,
        date: date
      });
      await getList()
    };

    onMounted(async () => {

      await getList()
    })

    async function Save() {
      try {
        saveLoading.value = true
        let request = new SaveAvailabilityRequest();
        request.requestList = listState.tableSource.list!.map(({ initialClass, ...rest }) => {
          return new ExaminerAvailabilityResponse({ ...rest });
        });
        request.date = date;
        await _Client.availabilityManagementClient.saveAvailability(request);
        back();
        message.success("success");
      }
      finally {
        saveLoading.value = false
      }
    }

    return {
      momentTimeZone,
      statusList,
      columns,
      tableLoading,
      ...toRefs(listState),
      getList,
      changeSearchParames,
      resetSearchParams,
      modifyExaminerId,
      modifyExaminerName,
      onSelectChange,
      examinerList,
      workStatusEnum,
      back,
      Save,
      saveLoading,
      date
    }
  },
  methods: {
    handleCheckboxChange(record: ExaminerAvailabilityResponse, value: boolean, event: any) {
      const checked = event.target.checked;
      if (checked) {

        record.availabilityStatus = value;
      }
      else {

        record.availabilityStatus = undefined;
      }
    }
  },
})