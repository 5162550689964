import { UnwrapRef } from '@vue/reactivity';
import { EnumSpeakingType } from './client';

export enum ExaminerResourcePlanningFormEnum {
  ExaminerSetForm = 'ExaminerSetForm',
  ExaminerNeedForm = 'ExaminerNeedForm',
}
export interface IEnableStatus {
  id: boolean;
  name: string;
}

export class LogDetailModel {
  list?: LogModel[] | undefined;
  pageSize?: number | undefined;
  pageIndex?: number | undefined;
  recordCount?: number | undefined;
  pageCount?: number | undefined;

  constructor(data?: LogDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
export class LogModel {
  createdTime?: Date | undefined;
  operation?: string | undefined;
  userName?: string | undefined;

  constructor(data?: LogModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export class CreateExaminerHubCmdForm {
  officeId!: string;
  cityId!: string;
  hubName!: string;
  abbreviation!: string;
  address!: string;
  capacity?: number | undefined;
  isEnable!: boolean;
  centerNo?: string | undefined;
  cityName?: string | undefined;
  isHomeWorking?: boolean;
}


